import React from "react"

import Layout from "../../../layouts/default"

import Breadcrumb from "../../../components/breadcrumb"
import Paragraph from "../../../components/paragraph"
import TextLink from "../../../components/textlink"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"

const LeichteSpracheFuehrungen = () => {
  return (
    <Layout backdrop="fuehrungen">
      <Seo
        title="Führungen in Leichter Sprache"
        description="Im Hölderlin∙turm gibt es auch Führungen in Leichter Sprache."
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Leichte Sprache",
              link: "/leichte-sprache",
            },
            {
              title: "Besuch",
              link: "/leichte-sprache/besuch",
            },
            {
              title: "Führungen",
              link: "/leichte-sprache/besuch/fuehrungen",
            },
          ]}
        />
        <PageTitle>Führungen in Leichter Sprache</PageTitle>
        <Paragraph>
          Im Hölderlin∙turm gibt es auch Führungen in Leichter Sprache. <br />
          Und es gibt Führungen für Menschen mit einer Seh∙behinderung. <br />
          Dafür muss man sich anmelden. <br />
          Man kann anrufen. <br />
          Die Telefon∙nummer ist: <br />
          0 70 71 - 204 18 60 <br />
          Oder man kann eine E-Mail schreiben: <br />
          <TextLink href="mailto:hoelderlinturm@tuebingen.de">
            hoelderlinturm@tuebingen.de
          </TextLink>
          .
        </Paragraph>

        <Paragraph>
          Die Führung geht eine Stunde. <br />
          Bei einer Führung können 15 Personen dabei sein. <br />
          Die Führung kostet für die ganze Gruppe 60 Euro.
        </Paragraph>
      </Stack>
    </Layout>
  )
}

export default LeichteSpracheFuehrungen
